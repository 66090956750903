/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/cover-intro-aws-3.png";
import post2 from "assets/images/cover_cost_opt_2.png";
// import post3 from "assets/images/cover_container_3.png";
import post4 from "assets/images/cover-serverless.png";
// import post5 from "assets/images/banner-vertical.png";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Khoá học nổi bật
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard
              image={post1}
              title="AWS nền tảng"
              description="Làm quen với đám mây AWS và các dịch vụ nền tảng: Điện toán, mạng, lưu trữ, cơ sở dữ liệu và bảo mật."
              action={{
                type: "external",
                route: "https://learn.awslibvn.com/#/course/aws-fundamental/",
                color: "info",
                label: "Bắt đầu",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard
              image={post4}
              title="Serverless trên AWS (sắp có)"
              description="AWS cung cấp các công nghệ để xây dựng, triển khai và quản lý ứng dụng hiệu quả, mà không cần quan tâm tới quản lý vận hành máy chủ."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "Bắt đầu",
                disabled: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Container trên AWS (sắp có)"
              description="Xây dựng các ứng dụng hiện đại với công nghệ Container cùng ECS, EKS, ECR và Fargate."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "Bắt đầu",
                disabled: true,
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <TransparentBlogCard
              image={post2}
              title="Tối ưu chi phí (Sắp có)"
              description="Tìm hiểu các công cụ quản lý và tối ưu chi phí để tận dụng tối đa AWS với giá thành rẻ nhất."
              action={{
                type: "internal",
                route: "#",
                color: "info",
                label: "Bắt đầu",
                disabled: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post5}
              title="AWS Service Demo Library"
              description="Thư viện giới thiệu các dịch vụ của AWS kèm theo demo."
              action={{
                type: "internal",
                route: "#",
                label: "Khám phá",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
