/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
// Presentation page components
import Contact from "pages/LandingPages/Author/sections/Contact";

// // Routes
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/banner.png";

const navRoutes = [
  {
    name: "Danh mục",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Khoá học",
        description: "Học AWS theo lộ trình",
        dropdown: true,
        collapse: [
          {
            name: "AWS căn bản",
            route: "/sections/page-sections/page-headers",
            // component: <PageHeaders />,
          },
        ],
      },
      {
        name: "Dịch vụ",
        description: "Tìm hiểu dịch vụ bạn sử dụng",
        dropdown: true,
        collapse: [
          {
            name: "Amazon EC2",
            route: "/sections/navigation/navbars",
            // component: <Navbars />,
          },
        ],
      },
    ],
  },
];

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={navRoutes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Thư viện AWS Tiếng Việt{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Bắt đầu hành trình lên mây với các khoá học được cập nhật thường xuyên bởi đội ngũ AWS Việt Nam
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
      <Contact />
      </Card>
      <MKBox px={1}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Presentation;
