/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import Posts from "pages/LandingPages/Author/sections/Posts";

// // Routes
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/banner.png";
// import { render } from "@testing-library/react";

import React from 'react';

import { API } from 'aws-amplify';

class Presentation extends React.Component {
  constructor() {
    super();
    this.state = {
      navRoutes: [],
      userGroupMembers: ""
    };
  }

  componentDidMount() {
    const apiName = 'dashboardData';
    const path = '/dashboardData';
    const init = {
      response: true, 
    };
  
    API
      .get(apiName, path, init)
      .then(response => {
        console.log(response);
        let data = response.data[0];
        let navRoutes = JSON.parse(data.navLinks);

        navRoutes.map(route => {
          route.icon = <Icon>link</Icon>;
          return route;
        })

        navRoutes.push({
          name: "Danh mục",
          icon: <Icon>view_day</Icon>,
          collapse: JSON.parse(data.navMenu),
        });

        this.setState({
          userGroupMembers: data.userGroupMembers,
          navRoutes: navRoutes,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <DefaultNavbar
          routes={this.state.navRoutes}
          sticky
        />
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                className="text-center"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Thư viện AWS Tiếng Việt{" "}
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                Bắt đầu hành trình lên mây với các khoá học miễn phí được cập nhật thường xuyên bởi đội ngũ AWS Việt Nam
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Posts />
          <Container sx={{ mt: 6 }}>
            <BuiltByDevelopers />
          </Container>
          <MKBox pt={6} pb={6}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                    Tham gia AWS Study Group
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    Cộng đồng {this.state.userGroupMembers} người dùng AWS tại Việt Nam
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  my={{ xs: 5, lg: "auto" }}
                  mr={{ xs: 0, lg: "auto" }}
                  sx={{ textAlign: { xs: "center", lg: "right" } }}
                >
                  <MKSocialButton
                    component="a"
                    href="https://www.facebook.com/groups/660548818043427"
                    target="_blank"
                    color="facebook"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-facebook" />
                    &nbsp;Tham gia
                  </MKSocialButton>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Card>
        <MKBox px={1}>
          <SimpleFooter />
        </MKBox>
      </>
    );
  }
}

export default Presentation;

/* eslint-disable */